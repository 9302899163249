<template>
  <label class="custom-toggle">
    <input type="checkbox" :checked="booleanValue" @change="toggle" />
    <span class="custom-toggle-slider rounded-circle"></span>
  </label>
</template>

<script>
export default {
  name: 'base-switch',
  props: {
    value: {
      type: String, // ou Boolean se possível
      required: true,
    },
  },
  computed: {
    booleanValue() {
      return this.value === 'true'; // Converte a string "true"/"false" para booleano
    },
  },
  methods: {
    toggle() {
      // Emite o evento 'input' com a string atualizada
      this.$emit('input', String(!this.booleanValue));
    },
  },
};
</script>
