import Vue from 'vue';

// Object initial state
const initialState = () => ({
  configuracoes: {},
  filteredConfigs: null,
  versaoServidor: null,
  editedConfigs: null,
});

// State object
const state = initialState();

// Getter object
const getters = {
  getConfig(state) {
    return state.configuracoes;
  },

  getVersaoServidor(state) {
    return state.versaoServidor;
  },

  getFilteredConfigs(state) {
    return state.filteredConfigs;
  },

  getEditedConfigs(state) {
    return state.editedConfigs;
  },
};

// Actions
const actions = {
  fetchConfig({ commit }) {
    const configuracoes = new Vue.prototype.$storeConfig.configuracoes();
    commit('setConfig', configuracoes);
  },

  async obterVersaoServidor({ commit }) {
    const comunicacao = new Vue.prototype.$comunicacao();

    const res = await comunicacao.send('/', {
      method: 'GET',
    });

    if (res.status === 200) {
      commit('setVersaoServidor', res.data.retorno.versao);
    }
  },

  async fetchFilteredConfigs({ commit }) {
    const comunicacao = new Vue.prototype.$comunicacao();
    const endpoint = `/servicoConfiguracao/v1/listarConfiguracoes`;
    // const epLocal = `http://localhost:8080/polgogames/servicoConfiguracao/v1/listarConfiguracoes`;

    try {
      const response = await comunicacao.send(endpoint, {
        method: 'POST',
      });

      if (response.status === 200) {
        commit('setFilteredConfigs', response.data.retorno);
        return { status: 200, msg: 'Configurações filtradas listadas com sucesso.' };
      } else {
        return {
          status: response.status || 500,
          msg: response.data.mensagem || 'Erro desconhecido ao listar configurações filtradas!',
          retorno: response.data.retorno,
        };
      }
    } catch (error) {
      return { status: 500, msg: 'Erro na requisição', error };
    }

    // try {
    //   const response = await fetch(epLocal, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY5NDc1MGVkLWM5MmEtNDk1Ny05MzAxLTBjZjhhYzBhYzgzNCIsInVzdWFyaW8iOiJyb290Iiwibm9tZSI6IlNVUEVSVVNFUiIsInRpcG8iOiJyb290IiwiaWF0IjoxNzMxNjEyMjU1LCJleHAiOjE3NDcxNjQyNTV9.X1R_12itVZeFWTyFCZ0vWP5R4JdhtLUhoGnJ6VGgMe8`,
    //     }
    //   });
  
    //   const data = await response.json();
  
    //   if (response.status === 200) {
    //     commit('setFilteredConfigs', data.retorno);
    //     return { status: 200, msg: 'Configurações listadas com sucesso.' };
    //   } else {
    //     return {
    //       status: response.status,
    //       msg: data.mensagem || 'Erro desconhecido ao listar configurações.',
    //       retorno: data.retorno,
    //     };
    //   }
    // } catch (error) {
    //   return { status: 500, msg: 'Erro na requisição', error };
    // }
  },

  async editConfigs({ commit }, { id, entradas }) {
    const comunicacao = new Vue.prototype.$comunicacao();
    const endpoint = `/servicoConfiguracao/v1/editarConfiguracoes`;
    // const epLocal = `http://localhost:8080/polgogames/servicoConfiguracao/v1/editarConfiguracoes`;

    try {
      const response = await comunicacao.send(endpoint, {
        method: 'POST',
        data: { id, entradas },
      });
    
      if (response.status === 200) {
        commit('setEditConfigs', response.data.retorno);
        return { status: 200, msg: 'Configurações alteradas com sucesso.' };
      } else {
        return {
          status: response.status || 500,
          msg: response.data.mensagem || 'Erro desconhecido ao editar configurações.',
          retorno: response.data.retorno,
        };
      }
    } catch (error) {
      return { status: 500, msg: 'Erro na requisição', error };
    }
    

    // try {
    //   const response = await fetch(epLocal, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY5NDc1MGVkLWM5MmEtNDk1Ny05MzAxLTBjZjhhYzBhYzgzNCIsInVzdWFyaW8iOiJyb290Iiwibm9tZSI6IlNVUEVSVVNFUiIsInRpcG8iOiJyb290IiwiaWF0IjoxNzMxNjEyMjU1LCJleHAiOjE3NDcxNjQyNTV9.X1R_12itVZeFWTyFCZ0vWP5R4JdhtLUhoGnJ6VGgMe8`,
    //     },
    //     body: JSON.stringify({ id, entradas }),
    //   });
  
    //   const data = await response.json();
  
    //   if (response.ok) {
    //     commit('setEditConfigs', data.retorno);
    //     return { status: 200, msg: 'Configurações alteradas com sucesso.' };
    //   } else {
    //     return {
    //       status: response.status,
    //       msg: data.mensagem || 'Erro desconhecido ao editar configurações.',
    //       retorno: data.retorno,
    //     };
    //   }
    // } catch (error) {
    //   return { status: 500, msg: 'Erro na requisição', error };
    // }
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setConfig(state, data) {
    state.configuracoes = data;
  },
  setVersaoServidor(state, data) {
    state.versaoServidor = data;
  },
  setFilteredConfigs(state, data) {
    state.filteredConfigs = data;
  },
  setEditConfigs(state, data) {
    state.editedConfigs = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
