<template>
  <div class="image-uploader">
    <div v-if="imageUrl" class="image-preview">
      <img :src="imageUrl" alt="Image Preview" />
    </div>
    <input type="file" ref="fileInput" @change="onFileChange" accept=".png" style="display: none" />
    <button @click="triggerFileInput" class="image-button">Escolher imagem</button>
  </div>
</template>

<script>
export default {
  name: 'BaseImagePicker',

  props: {
    selectedColor: {
      type: String,
      default: '#000000',
      required: false,
    },
    imageUrl: null,
  },
  data() {
    return {
      currentColor: this.selectedColor,
    };
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
  },

  watch: {
    currentColor(color) {
      if (!color || !color.length) this.currentColor = '#000000';
    },
  },
};
</script>

<style lang="scss" scoped>
.image-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-preview {
  margin-bottom: 10px;
  border: 1px solid black;
}

.image-button {
  border: none;
  background-color: #055ba3;
  color: white;
  border-radius: 0.3em;
  padding: 0.7em 1em;
  font-weight: 600;
}

.image-preview img {
  max-width: 100%;
  height: auto;
  display: block;
}
</style>
