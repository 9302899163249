// /compras/:anoCampanha/:identificacaoCampanha/:totalPorPagina/:paginaAtual
import Vue from 'vue';

// Object initial state
const initialState = () => ({
  compras: [],
  produtos: [],
  totalRegistros: 0,
  listaFiltros: [],
  carregando: false,
});

// State object
const state = initialState();

// Getter object
const getters = {
  getCompras(state) {
    return state.compras;
  },
  getTotalizador(state) {
    return state.totalRegistros;
  },
  getListaFiltrados(state){
    return state.listaFiltros
  },
  getLoader(state) {
    return state.carregando;
  },
  getProdutos(state) {
    return state.produtos;
  },
};

// Actions
const actions = {
  clearState({ commit }) {
    commit('RESET');
  },

  listarFiltrados({ commit }, { campanha }) {
    return new Promise(async resolve => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `produto/v1/produtos/listarFiltrados`;

      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data: {
          campanha
        }
      })

      if ([200, 201].includes(resposta.data.status)) {
        commit('setFiltros', resposta.data)
        commit('setListaFiltrados', resposta.data.retorno)
        resolve({ status: 200, msg: 'Lista de filtros obtida com sucesso!' });
      } else {
        commit('setFiltros', [])
        commit('setListaFiltrados', [])
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ops ocorreu um erro desconhecido durante sua requisição!',
        });
      }
    })
  },

  carregarCompras({ commit }, { campanha, paginacao, filtro }) {
    return new Promise(async resolve => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      let endpoint = `/produto/v1/compras/${campanha.ano}/${campanha.identificacao}/${
        paginacao.porPagina
      }/${paginacao.pagina}`;
      // Filtros
      if (filtro && filtro.cpf) endpoint += `/${filtro.cpf}`;
      if (filtro && filtro.produto) {
        endpoint += `?${
          /^\d+$/.test(filtro.produto)
            ? filtro.produto.length == 13
              ? 'produtoCodigoEAN'
              : 'produtoCodigo'
            : 'produtoDescricao'
        }=${filtro.produto}`;
      }
      const res = await comunicacao.send(endpoint, { method: 'GET' });
      commit('switchCarregando', false);
      if ([200, 201].includes(res.status)) {
        commit('setCompras', res.data.retorno.retorno);
        commit('setTotalizador', res.data.retorno.total);
        resolve({ status: 200, msg: 'Lista de compras obtidas com sucesso!' });
      } else if ([404].includes(res.status)) {
        commit('setCompras', []);
        commit('setTotalizador', 0);
        resolve({ status: 404, msg: 'Não há compras realizadas até o momento para esse filtro!' });
      } else {
        commit('setTotalizador', 0);
        resolve({
          status: res.status,
          msg: res.data.mensagem || 'Ops ocorreu um erro desconhecido durante sua requisição!',
        });
      }
    });
  },

  fetchProdutos({ commit }, { campanha, paginacao, fidelidade, descricao }) {
    return new Promise(async resolve => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const data = {}
      const endpoint = `/produto/v1/produtos/${campanha.ano}/${campanha.identificacao}/${
        paginacao.porPagina
      }/${paginacao.pagina}`;
      if(typeof fidelidade !== 'undefined'){
       data.fidelidade = fidelidade
      }
      if(typeof descricao !== 'undefined'){
        data.descricao = descricao
       }
       const res = await comunicacao.send(endpoint, { method: 'GET', data });
      commit('switchCarregando', false);

      if ([200, 201].includes(res.status)) {
        commit('setProdutos', res.data.retorno.retorno);
        commit('setTotalizador', res.data.retorno.total);
        resolve({
          status: 200,
          msg: 'Lista de produtos obtida com sucesso!',
          total: res.data.retorno.total,
        });
      } else if ([404].includes(res.status)) {
        commit('setProdutos', []);
        commit('setTotalizador', 0);
        resolve({ status: 404, msg: 'Não foi encontrado produtos!' });
      } else {
        commit('setProdutos', []);
        commit('setTotalizador', 0);
        resolve({ status: res.status || 500, msg: 'Erro desconhecido ao listar produtos' });
      }
    });
  },

  excluirProduto(store, { codigoEAN, campanha }) {
    return new Promise(async resolve => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/produto/v1/produtos/${codigoEAN}`;
      const res = await comunicacao.send(endpoint, { method: 'DELETE', data: { campanha: { ...campanha } } });

      if ([200, 201].includes(res.status)) {
        resolve({ status: 200, msg: 'Produto deletado com sucesso!' });
      } else if ([400].includes(res.status)) {
        resolve({ status: 400, msg: 'Não é possível excluir, o produto já possui compras' });
      } else {
        resolve({ status: res.status || 500, msg: 'Erro desconhecido ao excluir produto' });
      }
    });
  },

  incluirProduto({ commit }, { produto }) {
    return new Promise(async resolve => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `produto/v1/produtos`;

      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data: { ...produto }
      })

      commit('switchCarregando', false);
      if ([200, 201].includes(resposta.data.status)) {
        resolve({ status: 201, mensagem: 'Produto incluído com sucesso' })
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem })
      }
    })
  },

  incluirListaProdutos({ commit }, { campanha, produtos }) {
    return new Promise(async resolve => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `produto/v1/produtos/inserirLista`;

      const resposta = await comunicacao.send(url, {
        method: 'POST',
        data: {
          campanha,
          produtos
        }
      })

      commit('switchCarregando', false);
      if ([200, 201].includes(resposta.data.status)) {
        resolve({ status: 201, mensagem: 'Lista de produtos incluídos com sucesso', retorno: resposta.data.retorno })
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem, retorno: resposta.data.retorno })
      }
    })
  },

  atualizaProduto({ commit }, { produto }) {
    return new Promise(async resolve => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `produto/v1/produtos`;

      const resposta = await comunicacao.send(url, {
        method: 'PUT',
        data: { ...produto }
      })

      commit('switchCarregando', false);
      if ([200, 201].includes(resposta.data.status)) {
        resolve({ status: 201, mensagem: 'Produto atualizado com sucesso' })
      } else {
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem })
      }
    })
  }
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
  setCompras(state, data) {
    state.compras = data;
  },
  setProdutos(state, data) {
    state.produtos = data;
  },
  setTotalizador(state, data) {
    state.totalRegistros = data;
  },
  setListaFiltrados(state, data){
    state.listaFiltros = data
  },

  switchCarregando(state, data) {
    state.carregando = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
