<template>
  <div>
    <base-button type="primary" class="botao_cadastro" @click="modals.tabelaCores = true"
      >Abrir tabela cores</base-button
    >
    <modal
      :show.sync="modals.tabelaCores"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-xl"
      modalContentClasses="bg-secondary"
    >
      <card type="secondary" shadow header-classes="bg-white pb-4" body-classes="px-lg-5 py-lg-3" class="border-0">
        <template>
          <div v-for="mock in mockDados" :key="mock" class="d-flex flex-column">
            <div class="config-linha" />
            <div class="d-flex align-items-center">
              <div class="d-flex w-50 flex-column">
                <p class="config-nome">{{ mock.nome }}</p>
                <p class="config-descricao">{{ mock.descricao }}</p>
              </div>
              <div class="d-flex">
                <base-button type="primary" @click="abrirModal(mock)" class="config-botao"
                  ><i class="fas fa-image"></i
                ></base-button>
                <BaseColorPicker :selectedColor="mock.cor"></BaseColorPicker>
              </div>
            </div>
          </div>
          <div class="config-linha" />
        </template>
      </card>
    </modal>

    <modal
      :show.sync="modals.imagem"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-xl"
      modalContentClasses="bg-secondary"
    >
      <card type="secondary" shadow header-classes="bg-white pb-4" body-classes="px-lg-5 py-lg-3" class="border-0">
        <template>
          <div class="config-imagem">
            <img :src="this.imagemSelecionada" />
          </div>
        </template>
      </card>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'BaseColorTable',

  props: {
    selectedColor: {
      type: String,
      default: '#000000',
      required: false,
    },
  },
  data() {
    return {
      currentColor: this.selectedColor,
      modals: {
        tabelaCores: false,
        imagem: false,
      },

      mockDados: [
        {
          nome: 'COR_BACKGROUND_FORCADO_BOTOES',
          descricao:
            'Cor que será colocada no background dos botões do menu caso a config de forçar background esteja ativa',
          imagem: 'https://files.tecnoblog.net/wp-content/uploads/2022/09/stable-diffusion-imagem.jpg',
          cor: '#7317cc',
        },
        {
          nome: 'COR_DESTAQUE',
          descricao: 'Cor do fundo dos botões.',
          imagem: 'https://files.tecnoblog.net/wp-content/uploads/2022/09/stable-diffusion-imagem.jpg',
          cor: '#4F0F8D',
        },
      ],

      imagemSelecionada: '',
    };
  },
  methods: {
    abrirModal(mock) {
      this.imagemSelecionada = mock.imagem;
      this.modals.imagem = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.config-botao {
  padding: 1em 3em;
}

.config-nome {
  font-weight: 600;
  margin-bottom: 0.2em;
}
.config-linha {
  width: 100%;
  background-color: #cfcfcf;
  height: 2px;
  margin-bottom: 1em;
}
</style>
