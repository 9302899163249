<template>
  <div>
    <base-button type="primary" class="botao_cadastro" @click="this.abrirModal">Abrir tabela</base-button>
    <modal
      :show.sync="modals.tabela"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-xl"
      modalContentClasses="bg-secondary"
    >
      <card type="secondary" shadow header-classes="bg-white pb-4" body-classes="px-lg-5 py-lg-3" class="border-0">
        <template class="">
          <div class="d-flex flex-column justify-content-center align-items-center" style="gap: 0.8rem 0">
            <table class="styled-table">
              <thead>
                <tr>
                  <th scope="col" v-for="(key, index) in filteredKeys" :key="index" class="table-header">{{ key }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in objetoValues" :key="index">
                  <td v-for="(value, key) in item" :key="key" class="table-cell">
                    <base-input v-model="objetoValues[index][key]" v-if="key === 'chave'" disabled class="w-100" />
                    <base-input
                      v-model="objetoValues[index][key]"
                      v-if="key !== 'tipo' && key !== 'chave' && item.tipo !== 'cor'"
                      class="w-100"
                    />
                    <base-input
                      :colorPicker="true"
                      v-model="objetoValues[index][key]"
                      v-if="key === 'valor' && item.tipo === 'cor'"
                      class="w-100"
                    />
                    <!-- <input type="color" v-if="item.tipo === 'cor' && key === 'valor'"> -->
                  </td>
                </tr>
              </tbody>
            </table>
            <base-button type="primary" class="botao_cadastro" @click="alteraConfigs()">Salvar alterações</base-button>
          </div>
        </template>
      </card>
    </modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'BaseConfigTable',

  props: {
    values: {
      type: String,
      required: false,
    },
    chave: {
      type: String,
      required: true,
    },
    listAllConfigs: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      modals: {
        tabela: false,
      },

      mockDados: [
        {
          tipo: 'pwa',
          url: 'http://localhost:5666/',
        },
        {
          tipo: 'phonegap',
          url: 'com.nsc.polgodemo',
        },
        {
          tipo: 'phonegap',
          url: 'com.nsc.polgodemodev',
        },
        {
          tipo: 'pwa',
          url: 'https://testepolgodemo.polgo.com.br/',
        },
      ],

      objetoValues: {},
    };
  },

  mounted() {
    if (this.values) {
      this.objetoValues = JSON.parse(this.values);
    }
  },

  watch: {
    values(newVal) {
      if (newVal) {
        this.objetoValues = JSON.parse(newVal);
      }
    },
  },

  computed: {
    filteredKeys() {
      return this.objetoValues.length > 0 ? Object.keys(this.objetoValues[0]).filter((key) => key !== 'tipo') : [];
    },
  },

  methods: {
    ...mapActions({
      editConfigs: 'Configuracoes/editConfigs',
    }),

    abrirModal() {
      this.modals.tabela = true;
    },

    alert(text) {
      alert(text);
    },

    alteraConfigs() {
      console.log('TODAS AS CONFIGS', this.listAllConfigs);

      const filteredEntries = this.listAllConfigs.filter((entrie) => entrie.chave !== this.chave);
      const configToChange = this.listAllConfigs.find((entrie) => entrie.chave == this.chave);

      console.log('CONFIG QUE VAI MUDAR: ', configToChange);

      console.log('ANTES: ', filteredEntries);

      console.log('.VALOR NOVO: ', this.objetoValues);

      configToChange.valor = JSON.stringify(this.objetoValues);

      console.log('CONFIG TO CHANGE ALTERADA: ', configToChange);

      filteredEntries.push(configToChange);

      console.log('VALOR NOVO: ', JSON.stringify(`${this.objetoValues}`));

      console.log('DEPOIS: ', filteredEntries);

      const newValues = filteredEntries.map((entrie) => {
        return {
          chave: entrie.chave,
          valor: entrie.valor,
        };
      });

      const idConfig = this.listAllConfigs[0].id;

      try {
        this.editConfigs({ id: idConfig, entradas: newValues }).then(async (response) => {
          console.log('RESPOSTA', response);
          if (response.status === 200) {
            this.$swal({
              type: 'success',
              title: response.msg,
              toast: true,
              showConfirmButton: false,
              timer: 2000,
            });
            return;
          }

          this.$swal({
            type: 'error',
            title: 'Falha ao editar configurações!',
            toast: true,
            showConfirmButton: false,
            showDenyButton: false,
            showCancelButton: false,
            timer: 2000,
          });
        });
      } catch (error) {
        this.$swal({
          type: 'error',
          title: 'Falha ao copiar...',
          toast: true,
          showConfirmButton: false,
          showDenyButton: false,
          showCancelButton: false,
          timer: 2000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.styled-table {
  width: 100%;
  border-collapse: collapse;
}

.table-header {
  background-color: #f3f3f3;
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #ddd;
}

.table-cell {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
.config-botao {
  padding: 1em 3em;
}

.config-nome {
  font-weight: 600;
  margin-bottom: 0.2em;
}
.config-linha {
  width: 100%;
  background-color: #cfcfcf;
  height: 2px;
  margin-bottom: 1em;
}
</style>
