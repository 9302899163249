<template>
  <div
    class="form-group"
    :class="[
      { 'input-group': hasIcon },
      { 'has-danger': error },
      { focused: focused },
      { 'has-label': label || $slots.label },
      { 'has-success': valid === true },
      { 'has-danger': valid === false },
    ]"
  >
    <slot name="label">
      <label v-if="label" class="form-control-label" :class="labelClasses" v-tooltip="description">
        {{ label }}
        <span v-if="required">*</span>

        <i class="info fas fa-info-circle" v-if="description"></i>
      </label>
    </slot>

    <div class="input-group w-100">
      <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
        <span class="input-group-text">
          <slot name="addonLeft">
            <i :class="addonLeftIcon"></i>
          </slot>
        </span>
      </div>
      <slot v-bind="slotData">
        <input
          v-if="mask"
          :value="value"
          v-on="listeners"
          v-mask="mask"
          :masked="masked"
          v-bind="$attrs"
          class="form-control"
          :class="[{ 'is-valid': valid === true }, { 'is-invalid': valid === false }, inputClasses]"
          aria-describedby="addon-right addon-left"
        />
        <input
          v-if="!mask"
          :value="value"
          v-on="listeners"
          v-bind="$attrs"
          class="form-control"
          :class="[{ 'is-valid': valid === true }, { 'is-invalid': valid === false }, inputClasses]"
          aria-describedby="addon-right addon-left"
        />
        <input
          v-if="colorPicker"
          type="color"
           :value="value"
          @input="updateColor($event)"
          style="
            border-left: none;
            width: 10%;
            height: calc(1.5em + 1.25rem + 2px);
            font-size: 0.875rem;
            border: 1px solid #cad1d7;
          "
        />
      </slot>
      <div v-if="addonRightIcon || $slots.addonRight" class="input-group-append">
        <span class="input-group-text">
          <slot name="addonRight">
            <i :class="addonRightIcon"></i>
          </slot>
        </span>
      </div>

      <div v-if="secondaryButton && secondaryButtonIcon" class="input-group-append">
        <span
          class="input-group-text secondaryButton"
          @click="secondaryButtonClick()"
          :class="[
            { 'pointer-events-none-baseInput': disableSecundaryButton },
            { 'is-valid-icon': valid === true },
            { 'is-invalid-icon': valid === false },
          ]"
        >
          <i :class="secondaryButtonIcon"></i>
        </span>
      </div>

      <slot name="infoBlock"></slot>
      <slot name="helpBlock">
        <div class="text-danger invalid-feedback" style="display: block" :class="{ 'mt-2': hasIcon }" v-if="error">
          {{ error }}
        </div>
      </slot>
    </div>
  </div>
</template>
<script>
import { mask, masked } from 'vue-the-mask';

export default {
  directives: { mask, masked },
  inheritAttrs: false,
  name: 'base-input',
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)',
    },
    secondaryButton: {
      type: Boolean,
      default: false,
    },
    secondaryButtonIcon: {
      type: String,
    },
    valid: {
      type: Boolean,
      description: 'Whether is valid',
      default: undefined,
    },
    label: {
      type: String,
      description: 'Input label (text before input)',
    },
    error: {
      type: String,
      description: 'Input error (below input)',
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes',
    },
    inputClasses: {
      type: String,
      description: 'Input css classes',
    },
    value: {
      type: [String, Number],
      description: 'Input value',
      default: '',
    },
    addonRightIcon: {
      type: String,
      description: 'Addon right icon',
    },
    addonLeftIcon: {
      type: String,
      description: 'Addont left icon',
    },
    mask: {
      type: [String, Array],
      description: 'Mask',
    },
    masked: {
      type: [Boolean],
      description: 'Mask',
    },
    description: {
      type: String,
      description: 'Description to input',
      required: false,
    },
    disableSecundaryButton: {
      type: Boolean,
      description: 'Is used to disable the secondary button (using pointer-events: none;)',
      required: false,
      default: false,
    },
    colorPicker: {
      type: Boolean,
      description: 'Colorpicker dinamic',
      required: false,
      default: false,
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
      };
    },
    slotData() {
      return {
        focused: this.focused,
        ...this.listeners,
      };
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
  },
  methods: {
    updateValue(evt) {
      let value =
        evt.target.value && !this.masked && this.mask ? evt.target.value.replace(/\D/g, '') : evt.target.value;
      this.$emit('input', value);
    },
    onFocus(value) {
      this.focused = true;
      this.$emit('focus', value);
    },
    onBlur(value) {
      this.focused = false;
      this.$emit('blur', value);
    },
    secondaryButtonClick() {
      this.$emit('secondaryButtonClick');
    },
    updateColor(evt) {
      const color = evt.target.value;
      this.$emit('input', color);
    },
  },
};
</script>
<style lang="scss">
.secondaryButton {
  cursor: pointer;
}
.secondaryButton:hover {
  color: rgb(22, 13, 13);
}

.form-control-label {
  .info {
    font-size: 0.75rem;
    opacity: 0.5;
    cursor: pointer;
    margin-left: 0.5em;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }

  &:hover {
    .info {
      opacity: 0.7;
    }
  }
}

.pointer-events-none-baseInput {
  pointer-events: none;
  background-color: #e9ecef;
  span {
    background-color: #e9ecef;
  }
}

.is-valid-icon {
  border: solid #2dce89 2px;
}

.is-invalid-icon {
  border: solid #fb6340 2px;
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}
</style>
