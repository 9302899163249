<template>
  <component :is="tag" :class="[{ show: isOpen }, { dropdown: direction === 'down' }, { dropup: direction === 'up' }]"
    aria-haspopup="true" :aria-expanded="isOpen" @click="toggleDropDown" v-click-outside="closeDropDown">
    <slot name="title">
      <a class="dropdown-toggle nav-link" :class="{ 'no-caret': hideArrow }" data-toggle="dropdown">
        <i :class="icon"></i>
        <span class="no-icon">{{ title }}</span>
      </a>
    </slot>
    <ul id="dropdownMenu" class="dropdown-menu" style="cursor: auto" ref="menu"
      :class="[{ 'dropdown-menu-right': position === 'right' }, { show: isOpen }, menuClasses]">
      <slot></slot>
    </ul>
  </component>
</template>
<script>
export default {
  name: 'base-dropdown',
  props: {
    direction: {
      type: String,
      default: 'down',
    },
    title: {
      type: String,
      description: 'Dropdown title',
    },
    icon: {
      type: String,
      description: 'Icon for dropdown title',
    },
    position: {
      type: String,
      description: 'Position of dropdown menu (e.g right|left)',
    },
    menuClasses: {
      type: [String, Object],
      description: 'Dropdown menu classes',
    },
    hideArrow: {
      type: Boolean,
      description: 'Whether dropdown arrow should be hidden',
    },
    appendToBody: {
      type: Boolean,
      default: true,
      description: 'Whether dropdown should be appended to document body',
    },
    tag: {
      type: String,
      default: 'li',
      description: 'Dropdown html tag (e.g div, li etc)',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    async toggleDropDown() {
      await new Promise((resolve) => setTimeout(resolve, 50));
      this.isOpen = !this.isOpen;
      this.$emit('change', this.isOpen);
    },

    async closeDropDown() {
      if (!this.isOpen) return;

      this.isOpen = false;
      this.$emit('change', this.isOpen);
    },
  },
};
</script>
<style>
#dropdownMenu.acesso-dropdown-menu{
  min-width: 14rem !important;
}

.dropdown {
  list-style-type: none;
}

.dropdown .dropdown-toggle {
  cursor: pointer;
}
</style>
