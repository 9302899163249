<template>
  <div>
    <ItemSiderbar v-for="(pagina, idx) in listaSidebar" :key="idx" :dados="pagina" />
  </div>
</template>

<script>
import { Permissoes } from '../../../lib/Helper';

import listaPaginas from './listaPaginas.js';
import ItemSiderbar from '../ItemSiderbar.vue';

export default {
  name: 'MenuGAMEFICACAO',

  components: { ItemSiderbar },

  data() {
    return {
      campanha: null,
      configuracoes: null,
      tipoUsuario: null,
      itensSidebar: [],
      permissaoUsuario: null,
    };
  },

  computed: {
    listaSidebar() {
      const itensAutorizados = [];

      const itemPossuiPaginas = (item) => {
        let paginasAutorizadas = [];

        if (this.permissaoUsuario.acessoTotal) {
          paginasAutorizadas = paginasAutorizadas.concat(item.paginas);
        } else {
          const filtrarPaginas = item.paginas.filter((e) => this.permissaoUsuario.paginas.includes(e.caminho));

          if (filtrarPaginas.length) paginasAutorizadas = paginasAutorizadas.concat(filtrarPaginas);
        }

        if (paginasAutorizadas.length) itensAutorizados.push({ ...item, paginas: paginasAutorizadas });
      };

      const itemNaoPossuiPaginas = (item) => {
        if (item.titulo.toLowerCase() === 'configurações' && this.tipoUsuario !== 'lojista') {
          return;
        }

        if (this.permissaoUsuario.paginas.includes(item.caminho) || this.permissaoUsuario.acessoTotal) {
          itensAutorizados.push(item);
        }
      };

      this.itensSidebar.forEach((item) => {
        if (item.paginas.length) itemPossuiPaginas(item);
        else itemNaoPossuiPaginas(item);
      });

      return itensAutorizados;
    },
  },

  async beforeMount() {
    this.configuracoes = this.$storeConfig.configuracoes();
    this.tipoUsuario = this.$autenticacao.logado().tipo;
    this.itensSidebar = listaPaginas;
    this.permissaoUsuario = Permissoes.obter();

    if (this.$campanha.obterCampanhaAtual()) {
      this.campanha = this.$campanha.obterCampanhaAtual();
    } else {
      this.campanha = null;
    }
  },

  methods: {},
};
</script>
