import Vue from 'vue';

// Object initial state
const initialState = () => ({
  list: [],
  docs: [],
  document: null,
  documentLinks: [],
  docTotal: 0,
  carregando: false,
  valorTotalEscaneado: 0,
  quantidadeVendas: 0,
  quantidadeVendasRetrocompativel: false, // por padrão, diz que não é necessário manter retorcompatibilidade para este campo
  percentualIdentificadoAssociado: [],
  documentosFiscais: [],
  engajamentoPorCPF: {
    usuariosIdentificados: 0,
    usuariosNaoIdentificados: 0,
  },
  linkExportacao: '',
  documentoAtual: null,
  proximoDocumento: null,
  documentoAnterior: null,
  relatorioRankingProdutos: null,
  linkDownload: null,
});

// State object
const state = initialState();

// Getter object
const getters = {
  getList(state) {
    return state.list;
  },

  getDocs(state) {
    return state.docs;
  },

  getRelatorioRankingProdutos(state) {
    return state.relatorioRankingProdutos;
  },

  getLinkDownloas(state) {
    return state.linkDownload;
  },

  getTotalizador(state) {
    let total = 0;
    state.list.map((i) => {
      total += i.valorTotal;
    });
    return total;
  },

  getDocument(state) {
    return state.document;
  },

  getDocumentLinks(state) {
    return state.documentLinks;
  },

  getTotalDocs(state) {
    return state.docTotal;
  },

  getLoader(state) {
    return state.carregando;
  },

  getLinkExportacao(state) {
    return state.carregando;
  },

  getValorTotalEscaneado(state) {
    return state.valorTotalEscaneado;
  },

  getQuantidadeVendas(state) {
    return state.quantidadeVendas;
  },

  getQuantidadeVendasRetrocompativel(state) {
    return state.quantidadeVendasRetrocompativel;
  },

  getPercentualIdentificadoAssociado(state) {
    return state.percentualIdentificadoAssociado;
  },

  getEngajamentoPorCPF(state) {
    return state.engajamentoPorCPF;
  },

  getDocumentosFiscais(state) {
    return state.documentosFiscais;
  },

  getDocumentoAtual(state) {
    return state.documentoAtual;
  },

  getProximoDocumento(state) {
    return state.proximoDocumento;
  },

  getDocumentoAnterior(state) {
    return state.documentoAnterior;
  },
};

// Actions
const actions = {
  fetchList({ commit }, campanha) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/documentoFiscal/v1/documentos/${campanha.ano}/${campanha.identificacao}/associados`;
      let res = await comunicacao.send(url, { method: 'POST' });
      if ([200, 201].includes(res.status)) {
        commit('setList', res.data.retorno);
      } else {
        commit('setList', []);
      }
      resolve();
    });
  },

  fetchDocumentosFiscais({ commit }, { campanha, paginacao }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      let res;
      const comunicacao = new Vue.prototype.$comunicacao();

      if (campanha) {
        const endpoint = `/documentoFiscal/v1/documentos/${campanha.ano}/${campanha.identificacao}/${paginacao.porPagina}/${paginacao.pagina}`;
        res = await comunicacao.send(endpoint, {
          method: 'POST',
          data: {},
        });
      } else {
        const endpoint = `/documentoFiscal/v1/documentos/${paginacao.porPagina}/${paginacao.pagina}`;
        res = await comunicacao.send(endpoint, {
          method: 'GET',
          data: {},
        });
      }

      if (res.status == 200) {
        commit('setLoader', false);
        commit('setDocumentosFiscais', res.data.retorno.retorno);
        resolve({
          status: 200,
          mensagem: 'Documentos fiscais retornados com sucesso',
          premios: res.data.retorno,
        });
      } else {
        commit('setLoader', false);
        commit('setDocumentosFiscais', 0);
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição para obter os documentos fiscais',
        });
      }
    });
  },
  fetchRelatorioRankingProdutos({ commit }, { campanha, paginacao }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      let res;
      const comunicacao = new Vue.prototype.$comunicacao();

      const endpoint = `/documentoFiscal/v1/rankingProdutos/${campanha.ano}/${campanha.identificacao}/${paginacao.porPagina}/${paginacao.pagina}`;
      res = await comunicacao.send(endpoint, {
        method: 'GET',
      });

      if (res.status == 200) {
        commit('setLoader', false);
        commit('setRelatorioRankingProdutos', res.data.retorno);
        resolve({
          status: 200,
          mensagem: res.data.retorno.mensagem,
          premios: res.data.retorno,
        });
      } else {
        commit('setLoader', false);
        commit('setRelatorioRankingProdutos', 0);
        resolve({
          status: res.status || 500,
          mensagem:
            res.data.mensagem ||
            'Ocorreu um problema durante a requisição para obter o relatorio de ranking de produtos',
        });
      }
    });
  },

  exportarRankingDeProdutos({ commit }, { campanha, emailEnviar = '', retornaLink = true }) {
    return new Promise(async (resolve) => {
      commit('setCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/documentoFiscal/v1/exportarRankingDeProdutos`;

      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: {
          campanha,
          retornaLink,
          emailEnviar,
        },
      });
      commit('setCarregando', false);

      if ([200, 201].includes(res.status)) {
        commit('setLinkDownload', res.data.retorno);
        resolve({
          status: 200,
          msg: 'Rankikng de produto exportado com sucesso!',
          link: res.data.retorno,
        });
      } else if ([404].includes(res.status)) {
        resolve({
          status: 404,
          msg: 'O ranking de produtos não foi encontrado para exportação!',
        });
      } else {
        resolve({
          status: res.status || 500,
          msg: res.data.mensagem || 'Ocorreu um erro desconhecido ao exportar o ranking de produtos',
        });
      }
    });
  },

  loadDocument({ commit }, { numeroDocumento }) {
    return new Promise(async (resolve) => {
      const loadClient = async (url) => {
        return await comunicacao.send(url, { method: 'GET' });
      }
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();

      const endpoint = `/documentoFiscal/v1/documentos/1/1`;
      const res = await comunicacao.send(endpoint, {
        method: 'GET',
        data: {
          numeroDocumento
        },
      });

      if (res.status === 200 && res.data.retorno.retorno && res.data.retorno.retorno.length) {
        let document = { ...res.data.retorno.retorno[0] }

        if (!document.cabecalho.vendedor) document.cabecalho.vendedor = {}
        const response = await loadClient(`/consumidor/v1/consumidores/${document.usuario}`);
        if ([200].includes(response.status)) {
          document.consumidor = { ...response.data.retorno }
        }

        commit('setLoader', false);
        commit('setDocument', document);
        resolve({
          status: 200,
          mensagem: 'Documento fiscal obtido com sucesso',
          premios: res.data.retorno,
        });
      } else {
        commit('setLoader', false);
        commit('setDocument', null);
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição para obter o documento fiscal',
        });
      }
    });
  },

  editDocument({ commit }, { id, extra, uriImagem }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();

      const documentoFiscal = {
        extra,
        uriImagem
      }

      const endpoint = `/documentoFiscal/v1/documentoFiscal/${id}`;
      const res = await comunicacao.send(endpoint, {
        method: 'PUT',
        data: {
          documentoFiscal
        },
      });

      commit('setLoader', false);
      if (res.status === 200 && res.data.retorno) {
        let document = {}
        if (extra) document.extra = extra
        if (uriImagem) document.uriImagem = uriImagem

        commit('setUpdateDocument', document);
        resolve({
          status: 200,
          mensagem: 'Documento fiscal alterado com sucesso',
          premios: res.data.retorno,
        });
      } else {
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição para alterar o documento fiscal',
        });
      }
    });
  },

  fetchDocumentLinks({ commit }) {
    return new Promise((resolve, reject) => {
      const url = `https://polgoconfig.s3.amazonaws.com/documento-fiscal-opcoes.json`;
      return fetch(url).then(async (res) => {
        const documentLinks = await res.json();
        if ([200, 201].includes(res.status)) {
          commit('setDocumentLinks', documentLinks);
        } else {
          commit('setDocumentLinks', []);
        }
        resolve('Consultado com sucesso');
      }).catch(() => {
        commit('setDocumentLinks', []);
        reject('Erro ao buscar informação da SEFAZ')
      });
    });
  },

  fetchDocs({ commit }, { campanha, filtro, paginacao }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      let res;
      const comunicacao = new Vue.prototype.$comunicacao();

      /**
       * @deprecation Método POST vai ser extinto...
       */
      const data = campanha
        ? {
            ...filtro,
            anoCampanha: campanha.ano,
            identificacaoCampanha: campanha.identificacao,
          }
        : { ...filtro };
      const url = `/documentoFiscal/v1/documentos/${paginacao.porPagina}/${paginacao.pagina}`;
      res = await comunicacao.send(url, {
        method: 'GET',
        data,
      });

      if ([200, 201].includes(res.status)) {
        commit('setDocs', res.data.retorno.retorno);
        commit('setTotalDocs', res.data.retorno.total);
      } else {
        commit('setDocs', []);
        commit('setTotalDocs', 0);
      }
      resolve();
      commit('setLoader', false);
    });
  },

  fetchValorTotalEscaneado({ commit }, { campanha, data }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/documentoFiscal/v1/documentos/valorTotalEscaneado`;

      let filtros = {};

      if (campanha) {
        filtros['anoCampanha'] = campanha.ano;
        filtros['identificacaoCampanha'] = campanha.identificacao;
      }

      if (data) {
        filtros['data'] = data;
      }

      const res = await comunicacao.send(endpoint, {
        method: 'GET',
        data: { ...filtros },
      });

      if (res.status == 200) {
        commit('setLoader', false);
        commit('setValorTotalEscaneado', res.data.retorno.valorTotalEscaneado);

        // verificando se precisa manter retrocompatibilidade para este campo...
        if (res.data.retorno['quantidadeVendas'] === undefined) {
          commit('setQuantidadeVendasRetrocompativel', true);
          commit('setQuantidadeVendas', 0);
        } else {
          commit('setQuantidadeVendasRetrocompativel', false);
          commit('setQuantidadeVendas', res.data.retorno['quantidadeVendas']);
        }

        resolve({
          status: 200,
          mensagem: 'Valor total de cupons escaneados recuperado com sucesso',
          premios: res.data.retorno,
        });
      } else {
        commit('setLoader', false);
        commit('setValorTotalEscaneado', 0);
        commit('setQuantidadeVendas', 0);
        resolve({
          status: res.status || 500,
          mensagem:
            res.data.mensagem ||
            'Ocorreu um problema durante a requisição para obter o valor total de cupons escaneados',
        });
      }
    });
  },

  fetchPercentualIdentificadoAssociado({ commit }, { data }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/documentoFiscal/v1/documentos/percentualIdentificacaoAssociado`;

      const response = await comunicacao.send(endpoint, {
        method: 'GET',
        data,
      });

      if (response.status == 200) {
        commit('setLoader', false);
        commit('setPercentualIdentificadoAssociado', response.data.retorno);

        resolve({
          status: 200,
          mensagem: 'Percentuais de identificação por associado obtida com sucesso',
          premios: response.data.retorno,
        });
      } else {
        commit('setLoader', false);
        commit('setPercentualIdentificadoAssociado', 0);
        resolve({
          status: response.status || 500,
          mensagem:
            response.data.mensagem ||
            'Ocorreu um problema durante a requisição para obter o percentuais de identificação por associado',
        });
      }
    });
  },

  fetchEngajamentoPorCPF({ commit }, { dataInicial }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/documentoFiscal/v1/documentos/engajamentoPorCPF`;

      const response = await comunicacao.send(endpoint, {
        method: 'GET',
        data: { dataInicial },
      });

      if (response.status === 200) {
        commit('setLoader', false);
        commit('setEngajamentoPorCPF', response.data.retorno);

        resolve({
          status: 200,
          mensagem: 'Engajamento por CPF obtido com sucesso.',
          premios: response.data.retorno,
        });
      } else {
        commit('setLoader', false);
        commit('setEngajamentoPorCPF', { usuariosIdentificados: 0, usuariosNaoIdentificados: 0 });

        resolve({
          status: response.status || 500,
          mensagem:
            response.data.mensagem || 'Ocorreu um problema durante a requisição para obter o engajamento por CPF.',
        });
      }
    });
  },

  fetchResultadoVendedores({ commit }, { data }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);

      const comunicacao = new Vue.prototype.$comunicacao();

      const response = await comunicacao.send('/documentoFiscal/v1/resultadoVendedores/exportar/', {
        method: 'POST',
        data,
      });

      if (response.status === 200) {
        commit('setLoader', false);

        resolve({
          status: 200,
          mensagem: 'Resultado dos vendedores gerado com sucesso!',
        });
      } else {
        commit('setLoader', false);

        resolve({
          status: response.status || 500,
          mensagem:
            response.data.mensagem || 'Ocorreu um problema durante a requisição para gerar resultado dos vendedores.',
        });
      }
    });
  },

  exportarPercentualIdentificacaoAssociado({ commit }, { data }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = '/documentoFiscal/v1/percentualIdentificacaoAssociado/exportar/';

      const response = await comunicacao.send(
        url,
        {
          method: 'POST',
          data,
        },
        60000
      );

      commit('setLoader', false);
      if (response.status === 200) {
        commit('setLinkExportacao', response.data.retorno);
        resolve({
          status: 200,
          mensagem: 'Enviado para exportação!',
          link: response.data.retorno,
        });
      } else {
        resolve({
          status: response.status || 500,
          mensagem:
            response.data.mensagem ||
            'Ocorreu um problema durante a requisição para exportar percentual de identificação de associados.',
        });
      }
    });
  },

  enviarApuracao({ commit }, { id, data }) {
    return new Promise(async (resolve) => {
      commit('setLoader', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = `/documentoFiscal/v1/documentos/apurar/${id}`;

      const response = await comunicacao.send(url, {
        method: 'POST',
        data,
      });

      commit('setLoader', false);
      if (response.status === 200) {
        resolve({
          status: 200,
          mensagem: 'Apuração enviada com sucesso!',
          link: response.data.retorno,
        });
      } else {
        resolve({
          status: response.status || 500,
          mensagem:
            response.data.mensagem ||
            'Ocorreu um problema durante a requisição para enviar a apuração do documento fiscal.',
        });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },

  setList(state, data) {
    state.list = data;
  },

  setTotalDocs(state, data) {
    state.docTotal = data;
  },

  setDocs(state, data) {
    state.docs = data;
  },

  setRelatorioRankingProdutos(state, data) {
    state.relatorioRankingProdutos = data;
  },

  setLinkDownload(state, data) {
    state.linkDownload = data;
  },

  setLoader(state, data) {
    state.carregando = data;
  },

  setLinkExportacao(state, data) {
    state.linkExportacao = data;
  },

  setValorTotalEscaneado(state, data) {
    state.valorTotalEscaneado = data;
  },

  setQuantidadeVendas(state, data) {
    state.quantidadeVendas = data;
  },

  setQuantidadeVendasRetrocompativel(state, data) {
    state.quantidadeVendasRetrocompativel = data;
  },

  setPercentualIdentificadoAssociado(state, data) {
    state.percentualIdentificadoAssociado = data;
  },

  setDocumentosFiscais(state, data) {
    state.documentosFiscais = data;
  },

  setDocument(state, data) {
    state.document = data;
  },

  setDocumentLinks(state, data) {
    state.documentLinks = data;
  },

  setUpdateDocument(state, data) {
    state.document = { ...state.document, ...data };
  },

  setEngajamentoPorCPF(state, data) {
    state.engajamentoPorCPF = data;
  },
  setDocumentoAtual(state, data) {
    let indice = -1;
    state.documentoAtual = state.docs.find((e) => {
      indice++;
      return e.id == data;
    });
    state.proximoDocumento = state.docs[indice + 1] ? state.docs[indice + 1] : null;
    state.documentoAnterior = state.docs[indice - 1] ? state.docs[indice - 1] : null;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
