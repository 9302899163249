/**
 * Classe para formatar uma String.
 */
class Texto {

    /**
     * Construtor vazio.
     */
    constructor() { }

    /**
     * Limita o texto inserido e retorna em formato de string.
     * @param {String} string
     * @returns {string} - Texto cortado
     */
    static LimitaTexto(texto, limite, ret = "...") {
        if (texto && texto.length > limite) {
            return texto.substring(0, (limite - 2)).trim() + ret;
        }
        else {
            return texto;
        }
    }

    /**
     * Quebra o texto a partir de uma quantidade de caracteres sem quebrar as palavras
     * @param {String} string
     * @returns {string} - Texto cortado
     */
    static quebraTexto(texto, limite, quebra = "<br />") {
        if (texto.length > limite) {
            let textoS = texto.split(" ");
            let quebras = 1;

            texto = '';
            for (let i in textoS) {
                texto += textoS[i] + " ";
                if (texto.split(quebra).join().length > quebras * limite) {
                    texto += quebra;
                    quebras++;
                }
            }
        }
        return texto;
    }

    /**
     * Formata o conteudo do CNPJ ou CPF
     * @param {*} cnpjCpf CNPJ ou CPF
     */
    static formataCNPJCPF(cnpjCpf) {
        if (!cnpjCpf) return;

        cnpjCpf = cnpjCpf.toString().replace(/[^\d.-]/g, "");
        if (cnpjCpf.length == 14) {
            return cnpjCpf.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
        }
        else if (cnpjCpf.length == 11) {
            return cnpjCpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        }
    }

    /**
     * Formata o conteúdo do RH
     * @param {*} rg RG
     * @returns 
     */
    static formataRG(rg) {
        if (!rg) return;

        rg = rg.replace(/\D/g, "");
        rg = rg.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");
        return rg;
    }

    /**
     * Formata telefone
     * @param {*} tel telefone
     * @returns 
     */
    static formataTelefone(tel) {
        if (!tel) return;

        tel = tel.replace(/\D/g, "");             //Remove tudo o que não é dígito
        tel = tel.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
        tel = tel.replace(/(\d)(\d{4})$/, "$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
        return tel;
    }

    /**
     * Formata dinheiro
     * @param {*} dinheiro dinheiro
     * @returns 
     */
    static formataDinheiro(dinheiro) {
        if (!dinheiro) return;

        return dinheiro.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        })
    }

    /**
     * Formata numeros
     * @param {*} numero
     * @returns 
     */
    static formataNumero(numero) {
      if (!numero) return;
      const strNumero = numero.toString();

      return strNumero.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  /**
     * Formata data
     * @param {8} data data
     */
    static formataData(data) {
        if (!data) return '';
        
        return data.replace(/^(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1');
    }
}

export default Texto;
