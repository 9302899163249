<template>
  <div>
    <div v-if="!isProd" class="bg-danger user-select-none font-weight-bold text-white text-center py-2 d-block">
      Essa é uma versão de desenvolvimento, todos os dados aqui contidos são para fins de teste!
    </div>
    <base-nav :class="{
        'navbar-top': true,
        'navbar-dark': true,
        'navbar-top-test': !isProd,
        'pt-0': isProd,
      }" containerClasses="d-flex justify-content-center align-items-center w-100" id="navbar-main"
      :show-toggle-button="false" expand>
      <form class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
        <div class="form-group mb-0"></div>
      </form>
      <ul class="navbar-nav align-items-center d-none d-md-flex">
        <li class="nav-item dropdown">
          <base-dropdown class="nav-link pr-0 cursor-pointer" menuClasses="acesso-dropdown-menu">
            <div class="media align-items-center" slot="title">
              <!-- Background transparente na imagem de usuário admin -->
              <span class="minha-conta fas fa-user icon-sm icon-shape text-white rounded-circle shadow bg-icon-card">
                <!-- <img alt="Image placeholder" src="img/theme/team-4-800x800.jpg"> -->
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">Minha conta</span>
              </div>
            </div>
            <template>
              <div class="dropdown-header no-title">
                <h6 class="text-overflow m-0">Bem-vindo: {{ this.nome }}</h6>
              </div>
              <!--<div class="dropdown-divider"></div>-->
              <div v-if="paginaAtual === '/cashbacklight' && this.dataHoraFinalCampanha && this.diasRestantes > 0"
                class="dias-restantes" :class="{ 'dias-restantes--acabando': this.diasRestantes <= 3 }">
                <span>Dias restantes: {{ this.diasRestantes }}</span>
              </div>

              <div v-if="possuiAcessoALogs">
                <h6 class="d-flex justify-content-center bg-white"
                  style="margin-bottom: -24px; margin-left: 72px; margin-right: 72px">
                  Logs
                </h6>
                <hr class="m-3 position-sticky" style="z-index: -1" />

                <router-link to="/logs/requisicao" class="dropdown-item">
                  <i class="fas fa-history"></i>
                  <span>Requisições</span>
                </router-link>

                <router-link to="/logs/importacao" class="dropdown-item">
                  <i class="fas fa-history fa-flip-horizontal"></i>
                  <span>Importações</span>
                </router-link>

                <hr class="m-3" />
              </div>

              <router-link to="/" class="dropdown-item" v-on:click.native="logout">
                <i class="fas fa-running"></i>
                <span>Sair</span>
              </router-link>
            </template>
          </base-dropdown>
        </li>
      </ul>
    </base-nav>
  </div>
</template>

<style scoped>
.dias-restantes {
  padding: 0.25rem 1rem;
  background: #00a4ff;
  display: flex;
}

.dias-restantes span {
  color: #fff;
  font-size: 0.625rem;
  text-transform: uppercase;
  font-weight: bold;
}

.dias-restantes--acabando {
  background: #ff0000;
}
</style>

<script>
import { mapActions } from 'vuex';

export default {
  beforeMount() {
    this.configuracoes = this.$storeConfig.configuracoes();
    this.usuario = this.$autenticacao.logado().usuario;
    this.tipo = this.$autenticacao.logado().tipo;
    this.nome = this.$autenticacao.logado().nome;
  },
  mounted() {
    if (this.paginaAtual === '/cashbacklight' && this.tipo === 'lojista') {
      this.carregarCampanha();
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: '',
      configuracoes: null,
      paginaAtual: window.location.pathname,
      dataHoraFinalCampanha: '',
    };
  },
  computed: {
    isProd() {
      return window.location.href.startsWith(this.configuracoes.VUE_APP_PROD_BASE_URL);
    },
    diasRestantes() {
      const currentDate = new Date();
      const dataFinal = new Date(this.dataHoraFinalCampanha);

      return Math.floor((dataFinal - currentDate) / (1000 * 60 * 60 * 24));
    },
  },
  methods: {
    ...mapActions({
      fetchCampanhasCashback: 'Cashback/fetchCampanhasCashback',
    }),
    async carregarCampanha() {
      const { data } = await this.fetchCampanhasCashback({
        paginacao: {
          totalPorPagina: -1,
          paginaAtual: 1,
        },
        data: {
          listaAssociadoCNPJ: this.usuario,
          listaTipos: 'COMPRA',
          retornarListaProdutos: true,
        },
      });

      this.dataHoraFinalCampanha = data.retorno[0].dataHoraFinal;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },

    logout() {
      //localStorage.clear();
      localStorage.removeItem('login');
      localStorage.removeItem('campanha@DashboardACIMG');
      localStorage.removeItem('servicos@DashboardQRSorteios');
      this.$router.push({
        name: 'login',
      });
    },

    possuiAcessoALogs() {
      return ['root', 'admin'].includes(this.tipo);
    },
  },
};
</script>
