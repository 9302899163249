<template>
  <div class="w-100">
    <!-- fundo colorido -->
    <div
      class="credito d-flex flex-column justify-content-start"
      :class="{ fundoColorido }"
      v-if="fundoColorido"
      :style="{ backgroundColor: cor }"
    >
      <div class="icon d-flex align-items-center justify-content-center">
        <i :class="icone" :style="{ color: cor }"></i>
      </div>

      <p class="titulo text-uppercase">{{ label }}</p>

      <div class="valor" v-if="!tipoPorcentagem && !tipoPontos" v-tooltip="$texto.formataDinheiro(valorPrincipal)">
        {{ valorPrincipal | abreviarValorDinheiro }}
      </div>

      <div class="valor" v-else-if="tipoPontos" v-tooltip="$texto.formataNumero(valorPrincipal)">{{ formataPontos(valorPrincipal) }}</div>

      <div class="valor" v-else>{{ valorPrincipal }}%</div>

      <div class="valor-int" v-if="valorSecundario">
        <span v-if="!tipoPontos" v-tooltip="$texto.formataDinheiro(valorSecundario)">
          {{ valorSecundario | abreviarValorDinheiro }}
        </span>

        <span v-else v> {{ valorSecundario }}</span>
      </div>
    </div>

    <!-- fundo branco -->
    <div class="credito d-flex flex-column justify-content-start" :class="{ fundoColorido }" v-else>
      <div class="icon d-flex align-items-center justify-content-center" :style="{ backgroundColor: cor }">
        <i :class="icone"></i>
      </div>

      <p class="titulo text-uppercase">{{ label }}</p>

      <div class="valor" v-if="!tipoPorcentagem && !tipoPontos" v-tooltip="$texto.formataDinheiro(valorPrincipal)">
        {{ valorPrincipal | abreviarValorDinheiro }}
      </div>

      <div class="valor" v-else-if="tipoPontos" v-tooltip="$texto.formataNumero(valorPrincipal)">{{ formataPontos(valorPrincipal) }}%</div>

      <div class="valor" v-else>{{ $texto.formataNumero(valorPrincipal) }}%</div>

      <div class="valor-int" v-if="valorSecundario">
        <span v-if="!tipoPontos" v-tooltip="$texto.formataDinheiro(valorSecundario)">
          {{ valorSecundario | formataPontos(valorPrincipal) }}
        </span>

        <span v-else v-tooltip="$texto.formataNumero(valorSecundario)"> {{ formataPontos(valorSecundario) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, beforeMount, methods } from 'vue';

export default {
  name: 'CardBaseCredit',

  data() {
    return {
      configuracoes: null,
    };
  },

  beforeMount() {
    // Carregar configurações.
    this.configuracoes = this.$storeConfig.configuracoes();
  },

  props: {
    icone: {
      type: String,
      required: true,
    },
    cor: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    valorPrincipal: {
      type: Number,
      required: true,
    },
    valorSecundario: {
      type: Number,
      required: false,
    },
    fundoColorido: {
      type: Boolean,
      required: false,
      default: false,
    },
    tipoPorcentagem: {
      type: Boolean,
      required: false,
      default: true,
    },
    tipoPontos: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    formataPontos(pontos) {
      let pontosString = String(parseInt(pontos));
      let pontosFormatados = pontos;
      if (pontosString.length > 6) {
        pontosFormatados = pontosString.replace(/(\d)\d{5}$/, '.$1k');
      } else if (pontosString.length > 3) {
        pontosFormatados = pontosString.replace(/(\d)\d{2}$/, '.$1k');
      }
      // return `${pontosFormatados} pontos`;
      return `${pontosFormatados}`;
    },
  },

  filters: {
    abreviarValorDinheiro: function (dinheiro, pontos) {
      let dinheiroString = String(parseInt(dinheiro));
      let dinheiroFormatado = dinheiro;
      let prefixo = pontos ? '' : 'R$';

      if (dinheiroString.length > 6) {
        dinheiroFormatado = dinheiroString.replace(/(\d)\d{5}$/, ',$1M');
      } else if (dinheiroString.length > 3) {
        dinheiroFormatado = dinheiroString.replace(/(\d)\d{3}$/, '$1K');
      }

      return `${prefixo} ${dinheiroFormatado}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.credito {
  width: 100%;
  height: 180px;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  padding-left: 55px;
  box-shadow: -15px 0 20px 0 rgba(0, 0, 0, 0.075);
  padding-top: 45px;
  > .icon {
    width: 54px;
    height: 54px;
    left: -27px;
    top: 34px;
    border-radius: 100%;
    position: absolute;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;

    > i {
      font-size: 1rem;
    }
  }

  > .titulo {
    font-size: 0.7rem;
    margin-bottom: 5px;
  }

  > .valor {
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: 1.5px;

    > span {
      font-size: 0.75rem;
      font-weight: 400;
    }
  }

  .valor-int {
    font-size: 0.75rem;
    margin-top: 6px;
    letter-spacing: 1.5px;
  }

  &.fundoColorido {
    color: #fff;

    > .icon {
      background-color: #fff;
    }
  }
}
@media (max-width: 1380px) {
  .credito {
    padding-left: 40px;
  }
}
@media screen and (max-width: 1260px) {
  .credito {
    padding-left: 30px;
  }
}
@media screen and (max-width: 991px) {
  .credito {
    padding-top: 50px;
    margin-bottom: 15px;
    align-items: center;
    > .valor {
      font-size: 1.25rem;
    }

    > .icon {
      left: 16px;
      top: 16px;
    }
  }
}
</style>
