export default [
  {
    titulo: 'Home',
    condicao: () => true,
    icone: 'fas fa-home',
    caminho: 'gameficacao',
    paginas: [],
  },
  {
    titulo: 'Cadastro',
    condicao: () => true,
    icone: 'fas fa-plus',
    paginas: [
      {
        titulo: () => 'Criação de missão',
        condicao: () => true,
        icone: 'fas fa-bullseye',
        caminho: '#',
      },
      {
        titulo: () => 'Criação de nível',
        condicao: () => true,
        icone: 'fas fa-medal',
        caminho: '#',
      },
    ],
  },
  {
    titulo: 'Consultas',
    condicao: () => true,
    icone: 'fas fa-search',
    paginas: [
      {
        titulo: () => 'Listagem de missões',
        condicao: () => true,
        icone: 'fas fa-bullseye',
        caminho: '#',
      },
      {
        titulo: () => 'Listagem de níveis',
        condicao: () => true,
        icone: 'fas fa-medal',
        caminho: '#',
      },
    ],
  },
];
