<template>
  <div class="color-picker">
    <input type="color" class="inputColor" v-model="currentColor" @input="updateColor" />
    <base-input :type="'text'" v-model="currentColor" @update:modelValue="updateColor" />
  </div>
</template>

<script>
export default {
  name: 'BaseColorPicker',

  props: {
    selectedColor: {
      type: String,
      default: '#000000',
      required: false,
    },
  },
  data() {
    return {
      currentColor: this.selectedColor,
    };
  },
  methods: {
    updateColor() {
      this.$emit('newColorPicker', this.currentColor);
    },
  },

  watch: {
    currentColor(color) {
      if (!color || !color.length) this.currentColor = '#000000';
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin: 0 0 0 1em;
}

.color-picker {
  display: flex;
  height: 4rem;
  align-items: center;
  background-color: #eef1f5;
  border-radius: 0.5rem;

  .inputColor {
    cursor: pointer;
    margin-left: 0.5rem;
    padding: 0;
    border: none;
    border-radius: 5px;
    width: 1.5rem;
    height: 1.5rem;
  }
  .inputColor::-webkit-color-swatch {
    border: none;
    border-radius: 5px;
    padding: 0;
  }
  .inputColor::-webkit-color-swatch-wrapper {
    border: none;
    border-radius: 5px;
    padding: 0;
  }
}
</style>
